<template>
  <section class="py-24 px-4 lg:px-40 bg-hellblau border-b-2 border-white">
    <div
      class="flex flex-col  lg:flex-row w-full justify-between  items-center "
    >
      <div class="flex flex-col w-full lg:w-2/5 justify-start mb-16 lg:mt-0 ">
        <img :src="background" class="rounded-2xl" />
      </div>

      <div class="flex flex-col  justify-start  ">
        <p class="text-3xl    font-semibold leading-loose  ">
          Stefan Wisskirchen
        </p>
        <p class="text-lg  opacity-80  italic leading-loose  ">
          (Geschäftsführender Gesellschafter)
        </p>
        <p class="text-lg    mt-8 leading-loose  ">
          Er verantwortet unter anderem folgende Bereiche:
        </p>
        <ul class="text-lg  leading-loose ml-10 mt-8 list-disc">
          <li>Business Development</li>
          <li>Business Analyst</li>

          <li>Change Management</li>

          <li>Digitale Transformation</li>
          <li>Consulting</li>
          <li>Projektleitung</li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
import background from "@/assets/sw-business-kl.jpg";

export default {
  name: "Section1",
  setup() {
    return {
      background,
    };
  },
  components: {},
  mounted() {},
  methods: {},
};
</script>
<style scoped></style>

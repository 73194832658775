<template>
  <div>
    <Hero />
    <Section1 />
    <Section2 />

    <!-- <Section3 /> -->
  </div>
</template>

<script>
import Hero from "@/components/Team/Hero";
import Section1 from "@/components/Team/Section1";
import Section2 from "@/components/Team/Section2";
import Section3 from "@/components/Team/Section3";

export default {
  name: "Team",
  components: { Hero, Section1, Section2, Section3 },
  mounted() {},
  methods: {},
};
</script>
<style scoped></style>

<template>
  <section class="py-20 px-4 lg:px-10 bg-dunkelblau border-b-2 border-white">
    <div class="flex flex-col justify-center  space-y-20 items-center  mb-4 ">
      <div class=" w-3/5 flex flex-col">
        <p class="text-white italic  self-center mt-10 text-4xl">
          „Zusammenkommen ist ein Beginn, Zusammenbleiben ein Fortschritt,
          Zusammenarbeiten ein Erfolg.”
        </p>
        <p class="text-white italic  self-end mt-4 text-xl">
          Henry Ford
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import team1 from "@/assets/Bild_01.png";
import team2 from "@/assets/Bild_02.png";
import team3 from "@/assets/Bild_03.png";
import team4 from "@/assets/Bild_04.png";
import team5 from "@/assets/Bild_05.png";

export default {
  name: "Section",
  setup() {
    return {
      team1,
      team2,
      team3,
      team4,
      team5,
    };
  },
  components: {},
  mounted() {},
  methods: {},
};
</script>
<style scoped></style>

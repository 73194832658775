<template>
  <section class="py-24 px-4 lg:px-40 bg-dunkelgruen border-b-2 border-white">
    <div
      class="flex flex-col-reverse md:flex-col  lg:flex-row w-full justify-between  items-center "
    >
      <div class="flex flex-col  justify-start  ">
        <p class="text-3xl text-white  font-semibold leading-loose  ">
          Heiko Gemein
        </p>
        <p class="text-lg text-white  italic leading-loose  ">
          COO - Chief Operating Officer
        </p>
        <p class="text-lg text-white  mt-8 leading-loose  ">
          Er verantwortet unter anderem folgende Bereiche:
        </p>
        <ul class="text-lg text-white   leading-loose ml-10 mt-8 list-disc">
          <li>Consulting</li>
          <li>Prozessoptimierung</li>

          <li>Prozesssteuerung</li>

          <li>Digitalisierung</li>
          <li>Produktentwicklung</li>
          <li>Teamentwicklung</li>
          <li>etc.</li>
        </ul>
      </div>

      <div class="flex flex-col w-full lg:w-2/5 justify-start mb-16 lg:mt-0 ">
        <img :src="background" class="rounded-2xl" />
      </div>
    </div>
  </section>
</template>

<script>
import background from "@/assets/hg-business.jpg";

export default {
  name: "Section2",
  setup() {
    return {
      background,
    };
  },
  components: {},
  mounted() {},
  methods: {},
};
</script>
<style scoped></style>
